ul {
    list-style-type: none;
    margin: 2%;
    padding: 0;
    overflow: hidden;
}

li {
    float: left;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.active {
    border: solid;
    border-color: #c11fd8;
    color: #c11fd8;
    border-radius: 4px;
}